// Here you can add other styles
.error-message {
  color: #F8155D;
  border: 1px #F8155D solid;
  border-radius: 10px;
  padding: 4px;
  margin: 5px;
  text-align: center;
  background: #ffe0eb;
}

.error-message-minimal {
  color: #F8155D;
  padding: 4px;
  margin: 5px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rounded-mask {
  border-radius: 32px 32px 0 0;
  overflow: hidden;
}


.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
}

.hide-overflow {
  overflow: hidden;
}

.sundo-container {
  background-color: #FFF;
  border-radius: var(--container-border-radius);
  padding: var(--container-padding);

  &.medium {
    width: 500px;
    max-width: 100%;
  }
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

button.btn {
  border-radius: var(--container-border-radius);
}

// Styling to make sure ngx-timepicker-component is always on top
.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}



.edit-icon {
  font-size: 0.8em;
  cursor: pointer;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

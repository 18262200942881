// Variable overrides
:root {
  // Colors
  --primary-color: #F9B92F;
  --primary-dark-color: #D59307;
  --secondary-color: #426F85;
  --accent-color: #27C5E1;
  --accent-danger-color: #FF1664;
  --accent-succes-color: #1BCB71;

  // Border radius
  --container-border-radius: 32px;
  --button-border-radius: 16px;

  // Font
  --title-h1-font: normal 800 36px 'Nunito', serif;
  --title-h2-font: normal 700 28px 'Nunito', serif;
  --title-h3-font: normal 600 24px 'Nunito', serif;
  --title-h4-font: normal 400 20px 'Nunito', serif;
  --title-h5-font: normal 300 16px 'Nunito', serif;
  --title-h1-mobile-font: normal 800 28px 'Nunito', serif;
  --title-h2-mobile-font: normal 700 24px 'Nunito', serif;
  --title-h3-mobile-font: normal 600 20px 'Nunito', serif;
  --title-h4-mobile-font: normal 400 18px 'Nunito', serif;
  --title-h5-mobile-font: normal 300 16px 'Nunito', serif;
  --paragraph-font: normal 400 14px 'Poppins', sans-serif;

  // Padding
  --container-padding: 1rem;

  // Margins
  --margin-1: 8px;
  --margin-2: 12px;
  --margin-3: 16px;
  --margin-4: 24px;
  --margin-5: 32px;
}


$yellow: #F9B931;
$lightBlue: #24C4E0;
$darkBlue: #426F86;
$white : #fff;
$gray: #707b8e;

$green: #1bcb71;
$red: #F8155D;


.btn-primary, .page-item.active .page-link,label.active{
  background-color: $lightBlue !important;
  border-color: $lightBlue !important;
}

.btn-success{
background-color:$green !important;
}

.btn-danger{
background-color: $red !important;
}

.card.bg-primary{
background-color: $darkBlue !important;
}

span.bg-primary{
background-color: $darkBlue !important;
color: $white !important;
}

input{
color: $gray !important;
}


.card-header{
background-color: $darkBlue !important;
color: white;
}

tbody > tr:nth-child(odd),
.form-control:disabled,
{
background: rgba(66, 111, 134, 0.1) !important;
}




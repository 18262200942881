// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "~@coreui/coreui-pro/scss/coreui";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark";

// If you want to add something do it here
@import "custom";

.mat-dialog-container{
  white-space: pre-line;
}

// Spinkit
$spinkit-spinner-color: $body-color;
@import '~spinkit/scss/spinkit.scss';

// Necessary for all functionality of Materials
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
